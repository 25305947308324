import '@hexadrone/scss/app-front-page.scss';
import apiFetch from '@wordpress/api-fetch';
import Header from '@hexadrone/js/services/header';
import Utils from '@hexadrone/js/services/utils';
import Slider from '@hexadrone/js/services/slider';
const { __ } = wp.i18n;

const initAppFrontPage = () => {
  // Utils
  new Header();
  Utils.hover();
  Utils.click();
  Utils.bootstrap();
  Utils.searchbox();
  Utils.lazy();
  Utils.obfuscation();
  Utils.language();

  // Slider
  Slider.hero();
  Slider.strate_blog();

  // Strates
  strateSocials();

  function strateSocials() {
    const container = '[data-id="social-wall"]';

    if ($(container).length > 0) {
      apiFetch({
        path: `${window.Theme.iris_sso_instagram_url}?limit=6`,
        method: 'post',
      })
        .then((result) => {
          $(container).html(result.render);
          Utils.lazy();
        })
        .catch(() => {
          /** @type {string} */
          const domain = window.Theme?.domain || 'hexadrone';

          $(container).html(
            `<p class="hide socialWallMessage">${__('Une erreur est survenue pendant le chargement…' + '</p>', domain)}`
          );
        });
    }
  }
};

if (document.readyState === 'loading') {
  // Loading hasn't finished yet
  document.addEventListener('DOMContentLoaded', initAppFrontPage);
} else {
  // `DOMContentLoaded` has already fired
  initAppFrontPage();
}
